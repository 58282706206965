import { createSelector } from 'reselect';

import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import orderStatus from 'constants/orderStatus';
import { getCheckout } from 'redux/modules/checkout/selectors/get-checkout';
import { getCustomerOrderId } from 'redux/modules/order/selectors';
import { getStatus as getStatusById } from 'redux/modules/orders/selectors/get-order';

export const getResolveOrderPayment = createSelector(
  getCheckout,
  getCustomerOrderId,
  (state, customerOrderId) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    state.resolveOrderPayment[customerOrderId!] ??
    ({ loaded: false, loading: false, error: false } as const),
);

export const getResolveOrderPayments = createSelector(
  getCheckout,
  state => state.resolveOrderPayment,
);

export const getResolveOrderPaymentBalanceToPay = createSelector(getResolveOrderPayment, state =>
  state.loaded ? state.totalToPay : 0,
);

const getCustomerOrderIdFromArgs = (_: WtrState, customerOrderId: string) => customerOrderId;

export const getResolveOrderPaymentForId = createSelector(
  getCheckout,
  getCustomerOrderIdFromArgs,
  (state, customerOrderId) =>
    state.resolveOrderPayment[customerOrderId] ?? ({ loaded: false, loading: false } as const),
);

export const getFailedPaymentResolutionTypeForId = createSelector(
  getStatusById,
  getResolveOrderPaymentForId,
  (currentOrderStatus, resolveOrderPayment) => {
    if (currentOrderStatus !== orderStatus.PAYMENT_FAILED) {
      return null;
    }

    if (!resolveOrderPayment.loaded) {
      return FailedPaymentResolutionType.callCustomerCare;
    }

    if (resolveOrderPayment.resolved) {
      return null;
    }

    return resolveOrderPayment.paymentResolutionEnabled && resolveOrderPayment.paymentAttemptAllowed
      ? FailedPaymentResolutionType.selfServe
      : FailedPaymentResolutionType.callCustomerCare;
  },
);

export const isAnyResolveOrderPaymentLoading = createSelector(
  getCheckout,
  state =>
    state.resolveOrderPayment &&
    Object.keys(state.resolveOrderPayment).some(
      resolvePaymentId => state.resolveOrderPayment[resolvePaymentId]?.loading,
    ),
);
