import { connect } from 'react-redux';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';
import { hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import {
  getFirstPendingOrderId,
  isFirstPendingOrderLoaded,
} from 'redux/modules/orders/selectors/get-orders';

import NextOrder from './NextOrder';

export default connect(state => ({
  pendingOrderId: getFirstPendingOrderId(state),
  hidden: amendingOrder(state) || hasBookedSlot(state),
  isLoaded: isFirstPendingOrderLoaded(state),
}))(NextOrder);
